/* iPhone frame container */
.iphone-frame {
    width: 360px;
    height: 720px;
    border-radius: 40px;
    background-color: white;
    border: 16px solid #efefef;
    position: relative;
}

/* Top-notch area */
.iphone-notch {
    width: 150px;
    height: 30px;
    background-color: #efefef;
    position: absolute;
    top: 0;
    left: 48%;
    transform: translateX(-48%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

/* status-bar */
.status-bar {
    width: 95%;
    height: 20px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 25px;
    left: 5px;
}

/* Screen content styling */
.iphone-screen {
    margin-top: 60px;
    height: calc(100% - 80px);
}

/* Left-side buttons */
.left-buttons {
    position: absolute;
    left: -20px;
    top: 140px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.left-buttons .button {
    width: 6px;
    height: 40px;
    background-color: #efefef;
    border-radius: 5px;
}

/* Right-side button (Power button) */
.right-button {
    position: absolute;
    right: -20px;
    top: 200px;
    width: 6px;
    height: 80px;
    background-color: #efefef;
    border-radius: 5px;
}
