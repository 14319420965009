body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9;
}

button,
code,
textarea {
    font-family: "Roboto", sans-serif;
}

@keyframes right-slide-in {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes right-slide-out {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes up-slide-in {
    from {
        transform: translateY(5%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes up-slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(5%);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
