.wysiwyg-editor__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.wysiwyg-editor__wrapper--invalid {
    border: 1px solid #D84545;
}

.wysiwyg-editor__editor {
    padding: 0 16px;
}

.wysiwyg-editor__toolbar {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
    border-radius: 0;
    margin: 0;
}

.wysiwyg-editor__editor iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
}
